import type { NextPage } from 'next'
import PageHead from '../src/components/layout/page-head'
import Layout from '../src/components/layout'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import Image from 'next/legacy/image'
import NotFoundImage from '../src/assets/images/404.png'

export const getStaticProps = async ({ locale }: any) => ({
  props: {
    ...(await serverSideTranslations(locale, ['header', '404', 'footer'])),
  },
})

const Custom404: NextPage = () => {
  const { t } = useTranslation('404')

  return (
    <Layout className="text-center">
      <PageHead title={t('404-not-found')} />
      <h1 className="fw-bold">{t('404-not-found')}</h1>
      <p>{t('we-cant-find-that')}</p>
      <Image src={NotFoundImage} alt={t('404-not-found')} />
    </Layout>
  )
}

export default Custom404
